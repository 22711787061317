.reportTextTitle {
    color: red
}

.reportFont {
    font-family: Arial;
}

.reportFontSize{
    font-size: 12px;
}